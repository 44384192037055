<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title"> {{title}}</span>
                <span class="head__sub-title">&nbsp;/&nbsp;{{subtitle}}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <nav class="section__navigation plr__12 pt__12">
                <SegmentControl
                    :segment-control-class="'mb__12'"
                    :field_id="'component'"
                    :items="components"
                    v-model="component"
                    v-if="components.length>1"
                />
            </nav>
            <keep-alive>
            <component :is="component"
                       v-model="unitLmsg"
                       :unitId="unitId"
            /><!-- unitId!!! fix bug -->
            </keep-alive>
        </section>

        <section class="section__footer status_hidden">
            <!-- button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{action}} </button -->
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "UnitInfo",
        props: [
            'unitId',
        ],
        components: {
            'UnitInfo_Info':    () => import('./UnitInfo_Info.vue'),
            'UnitInfo_Sensors': () => import('./UnitInfo_Sensors.vue'),
            // 'UnitInfo_Traffic': () => import('./UnitInfo_Traffic.vue'),
            // 'UnitInfo_Locator': () => import('./UnitInfo_Locator.vue'),
            // 'UnitInfo_Options': () => import('./UnitInfo_Options.vue'),
            'UnitInfo_Info.lite':    () => import('./UnitInfo_Info.lite.vue'),
            'UnitInfo_Sensors.lite': () => import('./UnitInfo_Sensors.lite.vue'),
            // 'UnitInfo_Traffic.lite': () => import('./UnitInfo_Traffic.lite.vue'),
            // 'UnitInfo_Locator.lite': () => import('./UnitInfo_Locator.lite.vue'),
            // 'UnitInfo_Options.lite': () => import('./UnitInfo_Options.lite.vue'),
        },
        data() {
            return {
                components: [
                    {
                        name: this.$i18n.t('UnitInfo.components.title.Info'),
                        subtitle: this.$i18n.t('UnitInfo.components.descr.Information'),
                        component: 'UnitInfo_Info' + ((process.env.VUE_APP_PACKAGE !== "lite") ? '' : '.lite'),
                    },
                    /*{
                        name: this.$i18n.t('UnitInfo.components.title.Sensors'),
                        subtitle: this.$i18n.t('UnitInfo.components.descr.Settings'),
                        component: 'UnitInfo_Sensors' + ((process.env.VUE_APP_PACKAGE !== "lite") ? '' : '.lite'),
                    },*/
                    // {name: 'Traffic', component: 'UnitInfo_Traffic'+((process.env.VUE_APP_PACKAGE !== "lite")? '': '.lite'),},
                    // {name: 'Locator', component: 'UnitInfo_Locator'+((process.env.VUE_APP_PACKAGE !== "lite")? '': '.lite'),},
                    // {name: 'Option',  component: 'UnitInfo_Options'+((process.env.VUE_APP_PACKAGE !== "lite")? '': '.lite'),},
                ],
                component: 'UnitInfo_Info' + ((process.env.VUE_APP_PACKAGE !== "lite") ? '' : '.lite'),
                oldId: null,
                oldTime: null,
            }
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
                "unitsLmsgsByIds",
            ]),
            unit(){
                return this.unitsByIds[this.unitId]
            },
            unitLmsg() {
                return this.unitsLmsgsByIds[this.unitId]
            },
            title(){
                return (this.unitId>0) ? this.unit.name : ''
            },
            subtitle(){
                let selected = this.components.find(c => { return c.component === this.component})
                return selected.subtitle
            },
        },
        methods: {
            ...mapActions([
                "fetchUnitFullInfo",
            ]),
            onCancel(){
                this.$emit('close-dialog')
            },
            onSave(){
                this.$emit('close-dialog')
            },
            updateUnitInfo(){
                this.oldTime = this.time
                this.oldId = this.unitId
                if(this.unitId) {
                    //this.fetchUnitFullInfo(this.unitId)
                }
            },
        },
        created() {
            //this.currentItem = this.items[0]
            //if(this.unitId) this.fetchUnitFullInfo(this.unitId)
        },
        updated() {
            //console.log('UnitInfo updated', this.unitId)
            if(this.oldId !== this.unitId) {
                //console.log('!=', this.oldId, this.unitId)
                this.updateUnitInfo()
            }
        },
        mounted() {
            //console.log('UnitInfo mounted', this.unitId)
            this.updateUnitInfo()
        }
    }
</script>

